// Modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MerkatorMapModule, LocatieServiceDirective } from '@merkator/merkator-map';
import { MerkatorGridModule } from '@merkator/merkator-grid';
import { MerkatorDocumentsModule } from '@merkator/merkator-documents';
import { MerkatorTabLibraryModule } from '@merkator/merkator-tab-library';
import { MerkatorMapprintClientModule } from '@merkator/merkator-mapprint-client';
import { MaterialDesignModule } from './modules/material-design/material-design.module';
import { ResizableModule } from 'angular-resizable-element';

// Components
import { AppComponent } from './app.component';
import { MerkatorMapImplComponent } from './components/app-map-components/merkator-map-impl/merkator-map-impl.component';
import { MerkatorGridComponent } from './components/app-grid-components/merkator-grid/merkator-grid.component';
import { MerkatorDocumentsComponent } from './components/app-documents-components/merkator-documents/merkator-documents.component';
import { MenuToolbarComponent } from './components/app-toolbar-components/menu-toolbar/menu-toolbar.component';
import { SnackBarComponent } from './components/app-utility-components/snack-bar/snack-bar.component';
import { MenuItemComponent } from './components/app-toolbar-components/menu-item/menu-item.component';
import { MenuSettingsComponent } from './components/app-toolbar-components/menu-settings/menu-settings.component';

// Directives
import { DynamicComponentDirective } from './directives/dynamicComponent.directive';

// Services
import { HttpService } from './services/app-utililty-services/http.service';
import { MerkatorMapService } from './services/app-map-services/merkator-map.service';
import { ApplicationService } from './services/app-state-services/application.service';
import { ComponentmanagerService } from './services/app-state-services/componentmanager.service';

// Ngx-translate and http loader
import { TranslateLoader, TranslateModule, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// NgRx modules ed
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './reducers/theme-reducer/theme.reducer';
import { translate_reducer } from './reducers/translate-reducer/translate.reducer';
import { button_reducer } from './reducers/button-reducer/button.reducer';
import { grid_reducer, grid_reducer_object, grid_height_reducer } from './reducers/merkator-grid-reducer/merkator-grid.reducer';
import { mapconfig_reducer, maplayer_reducer, mapstyles_reducer,
  mapactions_reducer,
  mapcategories_reducer,
  maplegenditems_reducer,
  mapfeatures_reducer,
  mapfilters_reducer,
  mapdrawedfeature_reducer,
  mapviewparams_reducer,
  maphttp_reducer,
  mapfeatureidentifier_reducer,
  maptextstyle_reducer,
  mapfeaturestyles_reducer,
  mapdrawstate_reducer,
  mapcolorpicker_reducer} from './reducers/merkator-map-reducer/merkator-map.reducer';
import { MerkatorMapEffects } from './effects/merkator-map.effects';
import { MenuEffects } from './effects/menu.effects';
import { ButtonEffects } from './effects/button.effects';
import { menu_reducer } from './reducers/menu-reducer/menu.reducer';
import { SnackbarEffects } from './effects/snackbar.effects';
import { AppConfigEffects } from './effects/appconfig.effects';
import { snackbar_reducer } from './reducers/snackbar-reducer/snackbar.reducer';
import { demo_reducer } from './reducers/demo-reducer/demo.reducer';
import { MerkatorLayerControlComponent } from './components/app-map-components/merkator-layer-control/merkator-layer-control.component';
import { ButtoncontainerComponent } from './components/app-buttons-components/buttoncontainer/buttoncontainer.component';
import { ExpandablebuttonComponent } from './components/app-buttons-components/expandablebutton/expandablebutton.component';
import { TopbuttoncontainerComponent } from './components/app-buttons-components/topbuttoncontainer/topbuttoncontainer.component';
import { appconfig_reducer, apploading_reducer } from './reducers/appconfig-reducer/appconfig.reducer';
import { MatmodalComponent } from './components/app-utility-components/matmodal/matmodal.component';
import { MatModalEffects } from './effects/matmodal.effects';
import { matmodal_reducer } from './reducers/matmodal-reducer/matmodal.reducer';
import { MerkatorFeatureComponent } from './components/app-map-components/merkator-feature/merkator-feature.component';
import { MatbottomsheetComponent } from './components/app-utility-components/matbottomsheet/matbottomsheet.component';
import { bottomsheet_reducer } from './reducers/bottomsheet-reducer/bottomsheet.reducer';
import { MatBottomSheetEffects } from './effects/bottomsheet.effects';
import { ApplicationModule } from '@angular/core';

// OAUTH
// https://nice-hill-002425310.azurestaticapps.net/docs/migrations/v11-to-v12
import {
  AuthModule,
  OidcSecurityService,
  OpenIdConfiguration,
  AuthWellKnownEndpoints,
  LogLevel,
  PublicEventsService,
  EventTypes
} from 'angular-auth-oidc-client';
import { AppmainComponent } from './components/app-state-components/appmain/appmain.component';
import { AuthgaurdService } from './services/app-state-services/authgaurd.service';
import { AutoLoginComponent } from './components/app-state-components/auto-login/auto-login.component';
import { FeaturelistComponent } from './components/app-map-components/featurelist/featurelist.component';
import { MerkatorFeatureContainerComponent } from './components/app-map-components/merkator-feature-container/merkator-feature-container.component';
import { MerkatorGridEffects } from './effects/merkator-grid.effects';
import { MerkatorGridService } from './services/app-grid-services/merkator-grid.service';
import { ChooseorganisationComponent } from './components/app-state-components/chooseorganisation/chooseorganisation.component';
import { GkalFormImplComponent } from './components/gkal-components/gkal-form-impl/gkal-form-impl.component';
import { HtmlinputcomponentComponent } from './components/gkal-components/htmlinputcomponent/htmlinputcomponent.component';
import { MerkatorGooglelocatorComponent } from './components/app-map-components/merkator-googlelocator/merkator-googlelocator.component';
import { UnauthorizedComponent } from './components/app-state-components/unauthorized/unauthorized.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridprevioussaveddialogComponent } from './components/app-grid-components/gridprevioussaveddialog/gridprevioussaveddialog.component';
import { MerkatorMapprintComponent } from './components/app-map-components/merkator-mapprint/merkator-mapprint.component';
import { filter } from 'rxjs/operators';
import { ColorPickerModule } from 'ngx-color-picker';
import { LoaderService } from './services/app-utililty-services/loader.service';
import { LoaderInterceptorService } from './services/app-utililty-services/loaderinterceptor.service';
import { UserManagementComponent } from './components/app-toolbar-components/user-management/user-management.component';
import { UserManagementAdduserComponent } from './components/app-toolbar-components/user-management-adduser/user-management-adduser.component';
import { PatchNotesComponent } from './components/app-toolbar-components/patch-notes/patch-notes.component';
import { NmpExportMailingComponent } from './components/app-map-components/nmp-export-mailing/nmp-export-mailing.component';
import { ImageCarouselComponent } from './components/app-utility-components/image-carousel/image-carousel.component';
import { MerkatorPrintService } from './services/app-print-services/merkator-print.service';
import {CronJobMenuComponent} from "./components/app-utility-components/CronJobMenu/cron-job-menu.component";
import { FeatureExporterComponent } from './components/app-map-components/feature-exporter/feature-exporter.component';

// APP RESET FUNCTION
export function logout(reducer: any): any {
  return function(state: any, action: any): any {
    return reducer(action.type === '[App] reset' ? undefined : state, action);
  };
}

export class Awol implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    if (params.interpolateParams) {
      return params.interpolateParams['Default'] || params.key;
    }
    return params.key;
  }
}

@NgModule({
    declarations: [
        AppComponent,
        MerkatorMapImplComponent,
        DynamicComponentDirective,
        MerkatorGridComponent,
        MerkatorDocumentsComponent,
        MenuToolbarComponent,
        SnackBarComponent,
        MenuItemComponent,
        MenuSettingsComponent,
        MerkatorLayerControlComponent,
        ButtoncontainerComponent,
        ExpandablebuttonComponent,
        TopbuttoncontainerComponent,
        MatmodalComponent,
        MerkatorFeatureComponent,
        MatbottomsheetComponent,
        AppmainComponent,
        AutoLoginComponent,
        FeaturelistComponent,
        MerkatorFeatureContainerComponent,
        ChooseorganisationComponent,
        GkalFormImplComponent,
        HtmlinputcomponentComponent,
        MerkatorGooglelocatorComponent,
        UnauthorizedComponent,
        GridprevioussaveddialogComponent,
        MerkatorMapprintComponent,
        UserManagementComponent,
        UserManagementAdduserComponent,
        PatchNotesComponent,
        NmpExportMailingComponent,
        ImageCarouselComponent,
        CronJobMenuComponent,
        FeatureExporterComponent,
    ],
    imports: [
        BrowserModule,
        AuthModule.forRoot({}),
        HttpClientModule,
        AuthModule.forRoot({
          config: {
            authority: 'https://login.microsoftonline.com/a9b3476e-d359-4708-9b96-8eddaff74210/v2.0',
            //redirectUrl: 'https://test.savebelow.nl/app',
            redirectUrl: 'https://prod.savebelow.nl/app',
           // redirectUrl: 'http://localhost:4200/app',
            // postLogoutRedirectUri: 'https://localhost:44395/unauthorized',
            clientId: 'f52f6177-37e7-4f10-8e78-9a5918f32edd',
            scope: 'openid offline_access api://f52f6177-37e7-4f10-8e78-9a5918f32edd/cbyd.customer',
            responseType: 'code',
            // startCheckSession: true,
            useRefreshToken: true,
            // useRefreshToken: true,
            silentRenew: true,
            historyCleanupOff: true,
            ignoreNonceAfterRefresh: true,
            autoUserInfo: false,
            // silent_renew_url: 'https://nmpstoragedev.z6.web.core.windows.net/silent-renew.html',
            // silentRenewUrl: 'http://localhost:4200/silent-renew.html',
            disableIatOffsetValidation: true,
            maxIdTokenIatOffsetAllowedInSeconds: 60,
            logLevel: LogLevel.Error,
            renewTimeBeforeTokenExpiresInSeconds: 1000
            // all other properties you want to set
          }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: Awol
            }
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialDesignModule,
        FormsModule,
        ReactiveFormsModule,
        MerkatorMapModule,
        MerkatorGridModule,
        MerkatorDocumentsModule,
        ColorPickerModule,
        MerkatorTabLibraryModule,
        MerkatorMapprintClientModule,
        ResizableModule,
        ApplicationModule,
        StoreModule.forRoot({
            app: appconfig_reducer,
            apploading: apploading_reducer,
            theme: reducer,
            translate: translate_reducer,
            buttons: button_reducer,
            gridactive: grid_reducer,
            grid: grid_reducer_object,
            gridheight: grid_height_reducer,
            map: mapconfig_reducer,
            maplayers: maplayer_reducer,
            mapstyles: mapstyles_reducer,
            mapcategories: mapcategories_reducer,
            mapactions: mapactions_reducer,
            mapfeatures: mapfeatures_reducer,
            maplegenditems: maplegenditems_reducer,
            mapfilters: mapfilters_reducer,
            mapdrawedfeature: mapdrawedfeature_reducer,
            mapviewparams: mapviewparams_reducer,
            maphttpheaders: maphttp_reducer,
            mapfeatureidentifiers: mapfeatureidentifier_reducer,
            maptextstyles: maptextstyle_reducer,
            mapfeaturestyles: mapfeaturestyles_reducer,
            menu: menu_reducer,
            snackbar: snackbar_reducer,
            matmodal: matmodal_reducer,
            matbottomsheet: bottomsheet_reducer,
            demo: demo_reducer,
            donedrawstate: mapdrawstate_reducer,
            mapcolorpicker: mapcolorpicker_reducer

        }, { metaReducers: [logout], runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        } }) /*,
        StoreDevtoolsModule.instrument({
          maxAge : 30,
          logOnly: false
        })*/,
        EffectsModule.forRoot([
            MerkatorMapEffects,
            MenuEffects,
            SnackbarEffects,
            ButtonEffects,
            AppConfigEffects,
            MatModalEffects,
            MatBottomSheetEffects,
            MerkatorGridEffects
        ])
    ],
    providers: [
        HttpService, MerkatorMapService, ApplicationService, ComponentmanagerService,
        AuthgaurdService, MerkatorGridService, MerkatorPrintService,
        LoaderService,
        { provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptorService,
            multi: true
        }
        // TokenInterceptorService, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
  constructor(private readonly eventService: PublicEventsService) {
    this.eventService
        .registerForEvents()
        .pipe(filter((notification) => notification.type === EventTypes.ConfigLoaded))
        .subscribe((config) => console.log('ConfigLoaded', config));
}
}

// required for AOT compilation
export function HttpLoaderFactory(http: any): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
